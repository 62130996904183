import React from "react";

const InputField = ({ placeholder, icon, value, onChange, onFocus, onBlur, css, theme }) => {
  const isDarkMode = theme === "dark";
  return (
    <div className={`flex items-center border rounded-full overflow-hidden focus-within:border-primary ${
      isDarkMode ? "border-light" : "border-dark"
    }`}>
      <span className={`pl-3 ${isDarkMode ? "text-gray-400" : "text-gray-500"}`}>{icon}</span>
      <input
        className={`${css} ${
          isDarkMode ? "bg-gray-700 text-white placeholder:text-secondary-light" : "bg-white text-black"
        } focus:outline-none px-4 py-2`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};

export default InputField;
