import React, { useState, useRef, useEffect } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Tooltip } from "@mui/material";

const Dropdown = ({ data, css }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("All Category");
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const dropdownData = data;

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        type="button"
        className={css}
        id="options-menu"
        aria-expanded={isOpen}
        aria-haspopup="true"
        onClick={toggleDropdown}
      >
        <span className="block overflow-hidden text-ellipsis whitespace-nowrap max-w-[9rem]">
          {selectedValue}
        </span>
        {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </button>

      {isOpen && (
        <div
          className="origin-top-left absolute z-10 left-0 mt-2 w-56 border-2 border-gray-400 rounded-md shadow-lg bg-gray-100 ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            {dropdownData.map((value, ind) => (
              <Tooltip title={value} placement="right" key={ind}>
                <p
                  className="block overflow-hidden text-ellipsis whitespace-nowrap max-w-[100%] px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 cursor-pointer"
                  role="menuitem"
                  onClick={() => handleSelect(value)}
                >
                  {value}
                </p>
              </Tooltip>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
