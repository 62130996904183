const Cookies = require("js-cookie");
export const getAccountsList = async (accessToken) => {
  const device_uuid = Cookies.get("device_uuid");
  if (!device_uuid) {
    console.error("No device UUID found.");
    return {
      status: 200,
      data: { accounts: [] },
    };
  }
  const url = `https://api.trypadlock.com/userAccount`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ device_uuid: device_uuid }),
    });

    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error updating data:", error.message);
  }
};

export const addAccount = async (payload) => {
  const url = `https://api.trypadlock.com/userAccount/add`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error adding data:", error.message);
  }
};

export const removeAccount = async (payload, accessToken) => {
  const url = `https://api.trypadlock.com/userAccount/delete`;
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    });
    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error removing data:", error.message);
  }
};

export const removeAllAccounts = async (payload, accessToken) => {
  const url = `https://api.trypadlock.com/userAccount/deleteall`;
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    });
    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error removing all data:", error.message);
  }
};

export const switchAccount = async (payload, accessToken) => {
  const url = `https://api.trypadlock.com/userAccount/switch`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    });
    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error switching data:", error.message);
  }
};
