/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import wave from "../../assets/ic_wave.svg";
import ChatInputField from "../../components/ChatInputField";
import Button from "../../components/button";
import Loading from "../loading";
import { useNavigate } from "react-router-dom";
import { checkAndRefreshToken } from "../../utils/authUtils";
import {
  addMessageToSession,
  base_url,
  getFilesList,
} from "../../apis/recommendation/recommendation";
import { toast } from "react-toastify";
import { DataContext } from "../../components/layout/layout";
import { devApis } from "../../constants/constant";
import { CircularProgress, Skeleton, Tooltip, Typography } from "@mui/material";
import Cookies from "js-cookie";
const GreetPage = ({ dataFromSidebar }) => {
  const { handleHomePage, handleDataFromLanding } = useContext(DataContext);
  const navigate = useNavigate();

  const [fileNames, setFileNames] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getFileNames = async () => {
    const files = await getFilesList();
    const fileNames = files.map((file) => file.s3_key);
    setFileNames(fileNames);
  };

  const fetchChatHistory = async () => {
    const token = await checkAndRefreshToken();
    if (!token) navigate("/login");
    try {
      const res = await fetch(`${base_url}/get_chat_history`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200 || res.status === 201) {
        const { history } = await res.json();
        const humanData = history.filter((item) => item.type === "human");

        const contentFrequency = humanData.reduce((acc, item) => {
          acc[item.content] = (acc[item.content] || 0) + 1;
          return acc;
        }, {});

        const top4Contents = Object.entries(contentFrequency)
          .sort((a, b) => b[1] - a[1])
          .slice(0, 4)
          .map(([content, frequency]) => ({ content, frequency }));

        setHistoryData(top4Contents);
      } else {
        return {};
      }
    } catch (error) {
      console.error(error);
      toast.error("Error:", error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return null;
    }
  };

  const getPromptResponse = async (prompt) => {
    try {
      const promptResponse = await fetch(
        `${devApis.PYTHON_BASE_URL}/start_conversation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({
            recommendation_id: 0,
          }),
        }
      );

      const data = await promptResponse.json();
      const newSessionId = data.session_id;

      if (newSessionId) {
        const token = await checkAndRefreshToken();
        const response = await addMessageToSession(
          newSessionId,
          prompt,
          token,
          fileNames
        );

        handleHomePage(prompt);
        handleDataFromLanding({ recommendations: [] }, []);
        navigate("/selectRecommendations", {
          state: {
            recommendationId: 0,
            allRecommendations: { recommendations: [] },
            bookmarkedData: { bookmark_recommendations: [] },
            sessionId: newSessionId,
            promptData: response,
            isFileUpdate: false,
            promptInput: prompt,
            aiChildRecommendation: [
              response?.qualitative_question,
              response?.quantitative_question,
            ],
            navigatedFromHomePage: true,
          },
        });
      }
    } catch (error) {
      toast.error("error:", error.message);
      return null;
    }
  };

  useEffect(() => {
    fetchChatHistory();
    getFileNames();
  }, []);

  const LoadingRecommendationsMessage = () => {
    return (
      <div className="bg-white h-full rounded-lg flex justify-center">
        <Loading />
      </div>
    );
  };

  if (dataFromSidebar === false) return <LoadingRecommendationsMessage />;

  if (dataFromSidebar && dataFromSidebar?.recommendations)
    navigate("/landingPage");

  return (
    <div className="bg-white h-full rounded-lg flex justify-center">
      {isLoading ? (
        <div className="max-w-md flex justify-center items-center text-center flex-col gap-8 font-semibold text-xl">
          Please wait while we generate the response.
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%">
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
          />
        </div>
      ) : (
        <div className="max-w-4xl mx-auto w-full flex flex-col px-4 mb-2 md:mb-5 md:px-5 sio-recommendation-contener">
          <div className="mt-5 md:mt-24">
            <div className="sio-circle-icon sio-circle-warning size-16 md:size-20 mb-6 md:mb-9">
              <img
                src={wave}
                alt="wave"
                className=" w-[40px] md:w-[55px] mx-auto justify-center"
              />
            </div>
            <h1 className="text-primary-dark text-xl md:text-4xl mb-3 md:mb-5 font-semibold">
              <p className="text-lg md:text-xl font-manrope font-bold">
                <span className="font-light">Hey</span>{" "}
                {Cookies.get("userName")}!
              </p>
              What would you like to know?
            </h1>
            <div className="sio-card-shadow flex items-center mt-5 md:mt-8">
              <ChatInputField
                handleHomePage={handleHomePage}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </div>
            <p className="text-muted-dark font-light text-base mt-5 md:mt-8 mb-4">
              Or choose from top questions
            </p>
            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-3">
              {historyData.length > 0
                ? historyData.map((item, ind) => {
                    return (
                      <div className="sio-card-sm" key={ind}>
                        <div className="h-24 overflow-y-scroll scrollbar-none scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                          <Tooltip title={item.content}>
                            <p className="text-sm mb-auto line-clamp-4">
                              {item.content}
                            </p>
                          </Tooltip>
                        </div>
                        <Button
                          className="soi-btn-fab"
                          variant="default"
                          onClick={() => {
                            setIsLoading(true);
                            getPromptResponse(item.content);
                          }}>
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10.4791 7.83333H1.16665C0.930535 7.83333 0.732619 7.75347 0.572896 7.59375C0.413174 7.43403 0.333313 7.23611 0.333313 7C0.333313 6.76389 0.413174 6.56597 0.572896 6.40625C0.732619 6.24653 0.930535 6.16667 1.16665 6.16667H10.4791L6.39581 2.08333C6.22915 1.91667 6.14928 1.72222 6.15623 1.5C6.16317 1.27778 6.24998 1.08333 6.41665 0.916666C6.58331 0.763888 6.77776 0.684027 6.99998 0.677083C7.2222 0.670138 7.41665 0.75 7.58331 0.916666L13.0833 6.41667C13.1666 6.5 13.2257 6.59028 13.2604 6.6875C13.2951 6.78472 13.3125 6.88889 13.3125 7C13.3125 7.11111 13.2951 7.21528 13.2604 7.3125C13.2257 7.40972 13.1666 7.5 13.0833 7.58333L7.58331 13.0833C7.43053 13.2361 7.23956 13.3125 7.0104 13.3125C6.78123 13.3125 6.58331 13.2361 6.41665 13.0833C6.24998 12.9167 6.16665 12.7188 6.16665 12.4896C6.16665 12.2604 6.24998 12.0625 6.41665 11.8958L10.4791 7.83333Z"
                              fill="#111111"
                              fillOpacity="0.74"
                            />
                          </svg>
                        </Button>
                      </div>
                    );
                  })
                : Array.from({ length: 4 }).map((_, index) => {
                    return (
                      <div className="sio-card-sm" key={index}>
                        {Array.from({ length: 4 }).map((_, ind) => {
                          return (
                            <Typography
                              component="div"
                              key={ind}
                              variant="caption">
                              <Skeleton />
                            </Typography>
                          );
                        })}
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GreetPage;
