import { CircularProgress } from "@mui/material";
import React from "react";

const LoadingOverlay = () => {
    return (
        <div className="absolute z-50 top-0 left-0 w-screen h-screen flex justify-center items-center">
            <div className="bg-black bg-opacity-70 absolute h-full w-full"></div>
            <CircularProgress color="info" size={"3rem"} />
        </div>
    );
};

export default LoadingOverlay;
