import { forwardRef, useImperativeHandle, useState, useEffect } from "react";

export class ModalController {
    static children;
    static modalRef;

    static setChildren(children) {
        this.children = children;
    }

    static setModalRef(ref) {
        this.modalRef = ref;
    }

    static showModal() {
        if(this.modalRef) {
            this.modalRef.current.showModal();
        }
    }

    static hideModal() {
        if(this.modalRef) {
            this.modalRef.current.hideModal();
        }
    }


}

export default forwardRef(function ModalBackdrop(_, ref ) {

    const [ showModal, setShowModal ] = useState(false);

    useEffect(() => {
        ModalController.setModalRef(ref)
    }, [ref])

    useImperativeHandle(ref, () => {
        return {
            showModal() {
                setShowModal(true);
            },
            hideModal() {
                setShowModal(false);
            }
        }
    }, [])

    return <>
        {
            showModal && <div className="fixed z-50 top-0 left-0 w-screen h-screen">
            <div className="bg-black bg-opacity-70 h-full w-full flex items-center justify-center">
            {ModalController.children}
            </div>
            </div>
        }
    </>
})