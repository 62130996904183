/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./style.module.css";
import React, { useEffect, useRef, useState } from "react";

export default function FormComponent(props) {
  const {
    setState,
    BUCKET_NAME,
    setFileState,
    editedRow,
    setEditedRow,
    onSave,
    onUpdate,
    onChild,
    fileNames,
    clickAddBtn,
    setClickAddBtn,
  } = props;

  const fileTypeList = fileNames;
  const isEditingFile = editedRow && Object.keys(editedRow).length > 0;
  const btnList = clickAddBtn
    ? ["Save", "Cancel"]
    : [isEditingFile ? "Update" : "Save", "Cancel"];
  // const btnList =
  const objInterface = {
    name: "",
    category: "",
    // types: [],
    file_type: [],
    recommendation: "",
  };
  const [selectedObj, setSelectedObj] = useState({ ...objInterface });
  const formRef = useRef(null);

  useEffect(() => {
    if (isEditingFile) {
      const formattedFileTypes = editedRow.file_type.map((file) => file.Name);

      setSelectedObj({
        name: editedRow.Name || "",
        category: editedRow.Category || "",
        recommendation: editedRow.Content || "",
        file_type: formattedFileTypes || [],
      });
    } else {
      setSelectedObj({ ...objInterface });
    }
  }, [editedRow, isEditingFile]);

  const updateObject = (key, value) => {
    setSelectedObj((prevObj) => ({
      ...prevObj,
      [key]: value,
      file_type: Array.isArray(prevObj.file_type) ? prevObj.file_type : [],
    }));
  };

  const onDropDownClick = (e) => {
    e.stopPropagation();
    const divEle = e.target;
    const attr = "data-state";
    const isActive = divEle?.getAttribute(attr) === "active";
    divEle?.setAttribute(attr, isActive ? "inactive" : "active");
  };

  const onListClick = (e, fileType) => {
    e.stopPropagation();
    const checkbox = e.target.querySelector("input[type='checkbox']");
    const isActive = checkbox?.checked;

    setSelectedObj((prevObj) => {
      const updatedTypes = isActive
        ? prevObj.file_type.filter((type) => type !== fileType)
        : [...prevObj.file_type, fileType];

      return {
        ...prevObj,
        file_type: updatedTypes,
      };
    });

    if (checkbox) checkbox.checked = !isActive;
  };

  const onHandleClick = async (btn) => {
    let dataArr = JSON.parse(sessionStorage.getItem(BUCKET_NAME)) || [];
    console.log(btn);
    setClickAddBtn(false);
    switch (btn) {
      case "Save":
        if (clickAddBtn) {
          if (!selectedObj.name) {
            alert("Please Enter Name");
            formRef.current?.querySelector('input[name="name"]')?.focus();
            return;
          }
          if (!selectedObj.category) {
            alert("Please Enter Category");
            formRef.current?.querySelector('input[name="category"]')?.focus();
            return;
          }
          dataArr.push(selectedObj);
          setFileState(dataArr);
          await onChild(selectedObj);
          alert("Successfully Created Record");
          break;
        } else {
          if (!selectedObj.name) {
            alert("Please Enter Name");
            formRef.current?.querySelector('input[name="name"]')?.focus();
            return;
          }
          if (!selectedObj.category) {
            alert("Please Enter Category");
            formRef.current?.querySelector('input[name="category"]')?.focus();
            return;
          }
          dataArr.push(selectedObj);
          setFileState(dataArr);
          await onSave(selectedObj);
          alert("Successfully Created Record");
        }
        break;

      case "Update":
        const dataIndex = dataArr.findIndex(
          (item) =>
            item.name === editedRow.Name && item.category === editedRow.Category
        );

        dataArr[dataIndex] = { ...selectedObj };
        setFileState(dataArr);
        // sessionStorage.setItem(BUCKET_NAME, JSON.stringify(dataArr));
        console.log(selectedObj);
        await onUpdate(selectedObj); // Call the onUpdate function
        alert("Successfully Updated Record");

        break;

      case "Cancel":
        setSelectedObj({ ...objInterface });
        setEditedRow({});
        break;

      default:
        break;
    }

    setSelectedObj({ ...objInterface });
    setState(false);
    setEditedRow({});
  };

  return (
    <div className={styles.form__ele}>
      <div className={styles.wrapper}>
        {clickAddBtn ? (
          <p>Add Child Recommendation</p>
        ) : (
          <p>{isEditingFile ? "Update" : "Add"} Parent Recommendation</p>
        )}

        <form ref={formRef}>
          <div className={styles.form__group}>
            <label>Name</label>
            <input
              name="name"
              type="text"
              value={selectedObj.name}
              onChange={(e) => updateObject("name", e.target.value)}
              placeholder="Enter File Name"
            />
          </div>
          <div className={styles.form__group} data-type="dropdown">
            <label>File Type</label>
            <div role="button" onClick={onDropDownClick}>
              <div>
                <p
                  data-has-data={
                    Array.isArray(selectedObj.file_type) &&
                    selectedObj.file_type.length === 0
                  }
                >
                  {Array.isArray(selectedObj.file_type) &&
                  selectedObj.file_type.length === 0
                    ? "Select File Type"
                    : selectedObj.file_type.join(", ")}
                </p>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="ai ai-ChevronDown"
                  >
                    <path d="M4 9l8 8 8-8" />
                  </svg>
                </span>
              </div>
              <ul>
                {fileTypeList.map((type, index) => (
                  <li key={index} onClick={(e) => onListClick(e, type)}>
                    <input
                      type="checkbox"
                      checked={selectedObj.file_type.includes(type)}
                      readOnly
                    />
                    <p>{type}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.form__group}>
            <label>Category</label>
            <input
              type="text"
              name="category"
              placeholder="Enter Category"
              value={selectedObj.category}
              onChange={(e) => updateObject("category", e.target.value)}
            />
          </div>
          <div className={styles.form__group}>
            <label>Recommendation</label>
            <input
              type="text"
              name="recommendation"
              value={selectedObj.recommendation}
              placeholder="Enter Recommendation"
              onChange={(e) => updateObject("recommendation", e.target.value)}
            />
          </div>
          <div className={styles.btn__group}>
            {btnList.map((btn, index) => (
              <button
                type="button"
                data-type={btn}
                key={index}
                onClick={() => onHandleClick(btn)}
              >
                {btn}
              </button>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
}
