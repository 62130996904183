import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";

const LoadingDots = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return <div>Please Wait {dots}</div>;
};

const Loading = () => {
  return (
    <div className="flex flex-col items-center mt-24 rounded-lg bg-white text-center p-8 md:p-6">
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress size="32px" sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}/>
      <h1 className="text-primary-dark font-semibold mt-7">
        {<LoadingDots />}
      </h1>
      <p className="text-secondary-dark font-light text-base md:text-lg">We generate recommendations for you to plan your week</p>
    </div>
  );
};

export default Loading;
