/* eslint-disable no-unused-vars */
import React, { createContext, useState } from "react";
import Sidebar from "../sidebar/sidebar";

export const DataContext = createContext();

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dataFromSelectRecommendation, setDataFromSelectRecommendation] =
    useState(null);
  const [dataFromSidebar, setDataFromSidebar] = useState(null);
  const [dataFromRecommendation, setDataFromRecommendation] = useState([]);
  const [dataFromBookmark, setDataFromBookmark] = useState([]);
  const [dataFromChildren, setDataFromChildren] = useState(null);
  const [sendUploadedFileData, setSendUploadedFileData] = useState(null);
  const [deleteQuestions, setDeleteQuestions] = useState(false);
  const [selectQuestions, setSelectQuestions] = useState(null);
  const [homePageData, setHomePageData] = useState(null);

  const handleDataFromSelectRecommendation = (data) => {
    setDataFromSelectRecommendation(data);
  };

  const handleDataFromSidebar = (data) => {
    setDataFromSidebar(data);
  };

  const handleSelectQuestion = (data) => {
    setSelectQuestions(data);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const showSidebar = () => {
    setIsSidebarOpen(true);
  };

  const hideSidebar = () => {
    setIsSidebarOpen(false);
    setDataFromSidebar(null);
  };

  const handleDataFromLanding = (recommendation, bookmark) => {
    setDataFromRecommendation(recommendation);
    setDataFromBookmark(bookmark);
  };

  const handleDeleteQuestion = (data) => {
    setDeleteQuestions(data);
  };

  const handleHomePage = (data) => {
    setHomePageData(data);
  };

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      isSidebarOpen,
      dataFromSidebar,
      dataFromRecommendation,
      dataFromBookmark,
      sendUploadedFileData,
      selectQuestions,
    })
  );

  return (
    <DataContext.Provider
      value={{
        handleDataFromSelectRecommendation,
        handleDataFromSidebar,
        handleDataFromLanding,
        handleDeleteQuestion,
        dataFromSelectRecommendation,
        dataFromSidebar,
        setDataFromChildren,
        setSendUploadedFileData,
        handleHomePage,
        handleSelectQuestion,
      }}
    >
      <div className="bg-dark relative flex h-screen">
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          recId={dataFromSelectRecommendation}
          sendDataToParent={handleDataFromSidebar}
          toggleSidebar={toggleSidebar}
          showSidebar={showSidebar}
          hideSidebar={hideSidebar}
          dataFromChildren={dataFromChildren}
          sendUploadedFileData={sendUploadedFileData}
          deleteQuestions={deleteQuestions}
          selectedQuestions={handleSelectQuestion}
          homePageData={homePageData}
        />

        <div
          className={`flex-1 pb-20 md:pb-3 pt-10 md:pt-3 p-1 md:p-3 ${
            isSidebarOpen ? "lg:pl-0 ml-[0] lg:ml-[19rem]" : ""
          } transition-margin duration-300 h-[100%] w-[1%]`}
        >
          {childrenWithProps}
        </div>
      </div>
    </DataContext.Provider>
  );
};

export default Layout;
