/* eslint-disable no-unused-vars */
import styles from "./style.module.css";
import { useEffect, useRef, useState } from "react";
import FormComponent from "./partials";
import axios from "axios";
import { checkAndRefreshToken } from "../../../utils/authUtils";
import { toast } from "react-toastify";
import { getUserNames } from "../../../utils";
import { devApis } from "../../../constants/constant";

export default function CustomRecommendation() {
  const [editedRow, setEditedRow] = useState({});
  const tableRef = useRef(null);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormFData] = useState(null);

  const BUCKET_NAME = "fileTypeData";
  const [files, setFiles] = useState([]);

  async function CreateCustomRecommendation(model) {
    try {
      setFormFData(model);
      model = JSON.stringify(model);
      const token = await checkAndRefreshToken();
      await axios.post(`${devApis.GOLANG_BASE_URL}/api/file_type`, model, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      fetchData(model);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchData = async (model) => {
    const token = await checkAndRefreshToken();
    const url = `${devApis.GOLANG_BASE_URL}/api/file_types`;

    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      setFiles(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const [fileNames, setFileNames] = useState([]);

  const getFilesList = async () => {
    const token = await checkAndRefreshToken();
    const getUUID = await getUserNames();
    const uuid = getUUID.data.org_uuid;
    const params = new URLSearchParams();
    params.append("org_uuid", uuid);
    try {
      const res = await fetch(
        `${devApis.PYTHON_BASE_URL}/uploaded_files?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        const data = await res.json();

        const sortedFiles = data?.files?.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        const uniqueFileNames = [
          ...new Set(sortedFiles.map((file) => file.original_file_name)),
        ];
        setFileNames(uniqueFileNames);
      } else {
        return {};
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Error fetching files:", error.message);
      return {};
    }
  };

  useEffect(() => {
    fetchData();
    getFilesList();
  }, []);

  useEffect(() => {
    if (editedRow && Object.keys(editedRow).length > 0) {
      const editRow = tableRef?.current?.querySelector(
        'tr[data-editing="true"]'
      );
      if (editRow && editRow !== null) {
        const input = editRow.querySelector('td[data-type="filetype"] input');
        if (input && input !== null) {
          input.focus();
          sessionStorage.setItem("editingItem", JSON.stringify(editedRow));
        }
      }
    } else if (editedRow && Object.keys(editedRow).length <= 0) {
      sessionStorage.removeItem("editingItem");
    }
  }, [editedRow]);

  async function handleActionClick(action, file) {
    switch (action) {
      case "Create":
        setShowForm(true);

        break;
      case "Edit":
        console.log(file);
        console.log(files);
        setFileRecId(file);
        setEditedRow(files.file_types.find((item) => item.id === file));
        setShowForm(true);
        break;
      case "Delete":
        deleteAction(file);
        break;
      default:
        break;
    }
  }

  console.log(editedRow);

  const deleteAction = async (id) => {
    const confirm = window.confirm("Are you sure you want to Delete?");
    if (confirm) {
      const token = await checkAndRefreshToken();

      try {
        const response = await fetch(
          `${devApis.GOLANG_BASE_URL}/api/file_type/${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();

        alert("Successfully Deleted Record");

        setFiles((prevFiles) => {
          if (Array.isArray(prevFiles)) {
            return prevFiles.filter((f) => f.id !== id);
          }
          return [];
        });
        fetchData();
      } catch (error) {
        console.error("Error deleting file:", error);
        alert("Failed to delete record. Please try again.");
      }
    }
  };

  const [fileRecId, setFileRecId] = useState(null);

  const editRecommendation = async (file) => {
    try {
      const token = await checkAndRefreshToken();
      const response = await fetch(
        `${devApis.GOLANG_BASE_URL}/api/file_type/${fileRecId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(file),
        }
      );
      const updatedFile = await response.json();

      setFiles((prevFiles) =>
        prevFiles.map((f) => (f.id === fileRecId ? updatedFile : f))
      );
      setFiles(updatedFile);
      fetchData();
      alert("Successfully Updated Record");
    } catch (error) {
      console.error("Error updating file:", error);
      alert("Failed to update record. Please try again.");
    }
  };

  const actionsList = [
    {
      name: "Create",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="ai ai-Plus"><path d="M12 20v-8m0 0V4m0 8h8m-8 0H4"/></svg>`,
    },
    {
      name: "Edit",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="ai ai-Edit"><path d="M16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621z"/><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3"/></svg>`,
    },
    {
      name: "Delete",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="ai ai-TrashCan"><path d="M4 6h16l-1.58 14.22A2 2 0 0 1 16.432 22H7.568a2 2 0 0 1-1.988-1.78L4 6z"/><path d="M7.345 3.147A2 2 0 0 1 9.154 2h5.692a2 2 0 0 1 1.81 1.147L18 6H6l1.345-2.853z"/><path d="M2 6h20"/><path d="M10 11v5"/><path d="M14 11v5"/></svg>`,
    },
  ];

  console.log(fileNames);

  return (
    <div className={styles.list__wrapper}>
      {files?.file_types && files.file_types.length > 0 ? (
        <>
          <div className={styles.header__ele}>
            <span>Add File Type</span>
            <button onClick={() => handleActionClick("Create", {})}>Add</button>
          </div>
          <table ref={tableRef}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>ID</th>
                <th>Name</th>
                <th>Description</th>
                <th>Associate Files</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {files.file_types &&
                files.file_types.map((file, index) => {
                  const isEditingFile =
                    editedRow &&
                    Object.keys(editedRow).length > 0 &&
                    editedRow.id === file.id;
                  return (
                    <tr key={index} data-editing={isEditingFile}>
                      <td>{index + 1}</td>
                      <td>{file.id}</td>
                      <td>{file.name || "N/A"}</td>
                      <td data-type="filetype">{file.description}</td>
                      <td>
                        {[...new Set(file.associated_files)].map(
                          (file, ind, arr) => (
                            <div id={ind}>
                              {file}
                              {ind < arr.length - 1 && ","}
                            </div>
                          )
                        )}
                      </td>
                      <td data-type="actions">
                        <div>
                          {actionsList.map((action) => (
                            <button
                              key={action.name}
                              data-type={action.name}
                              title={action.name}
                              dangerouslySetInnerHTML={{
                                __html: action.icon,
                              }}
                              onClick={() => {
                                handleActionClick(action.name, file.id);
                              }}></button>
                          ))}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
      ) : (
        <div className={styles.stateMsg}>
          No data available. Do you want to add File Type?{" "}
          <span
            href="#"
            onClick={() => {
              setShowForm(true);
            }}>
            Click here
          </span>
        </div>
      )}

      {showForm && (
        <FormComponent
          setState={setShowForm}
          BUCKET_NAME={BUCKET_NAME}
          setFileState={setFiles}
          editedRow={editedRow}
          setEditedRow={setEditedRow}
          onSave={CreateCustomRecommendation}
          onUpdate={editRecommendation}
          fileNames={fileNames}
        />
      )}
    </div>
  );
}
