/* eslint-disable no-unused-vars */
import styles from "./style.module.css";
import { useEffect, useRef, useState } from "react";
import FormComponent from "./partials";
import { checkAndRefreshToken } from "../../../utils/authUtils";
import {
  CreateChildRecommendation,
  deleteChild,
  editChild,
  fetchData,
  getFileName,
} from "../../../apis/recommendation/recommendation";
import { toast } from "react-toastify";
import { devApis } from "../../../constants/constant";

export default function CustomRecommendation() {
  const [editedRow, setEditedRow] = useState({});
  const tableRef = useRef(null);
  const [showForm, setShowForm] = useState(false);

  const BUCKET_NAME = "fileTypeData";
  const [files, setFiles] = useState([]);

  const getChildRecommendations = async () => {
    try {
      const data = await fetchData();
      setFiles(data);
      console.log(data);
    } catch (err) {
      toast("Error:", err);
    }
  };

  const addChildRecommendations = async (model) => {
    const parentId = parentData.find((item) =>
      item.file_type.some((ft) => ft.Name === model.file_type[0])
    ).ID;
    delete model.recommendation_id;
    model["recommendation_id"] = parentId;
    try {
      await CreateChildRecommendation(model);
      await getChildRecommendations();
    } catch (err) {
      toast.error("Filed to add child Recommendation");
    }
  };

  const [fileNames, setFileNames] = useState([]);

  const getFileNameApi = async () => {
    try {
      const names = await getFileName();
      const fileNames = names.file_types.map((item) => item.name);
      setFileNames(fileNames);
    } catch (err) {
      toast.error("Error:", err);
    }
  };

  const [parentData, setParentData] = useState([]);

  const fetchParentData = async () => {
    const url = `${devApis.GOLANG_BASE_URL}/get_custom_parent_recommendations`;

    try {
      const token = await checkAndRefreshToken();
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setParentData(data);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getChildRecommendations();
    getFileNameApi();
    fetchParentData();
  }, []);

  useEffect(() => {
    if (editedRow && Object.keys(editedRow).length > 0) {
      const editRow = tableRef?.current?.querySelector(
        'tr[data-editing="true"]'
      );
      if (editRow && editRow !== null) {
        const input = editRow.querySelector('td[data-type="filetype"] input');
        if (input && input !== null) {
          input.focus();
          sessionStorage.setItem("editingItem", JSON.stringify(editedRow));
        }
      }
    } else if (editedRow && Object.keys(editedRow).length <= 0) {
      sessionStorage.removeItem("editingItem");
    }
  }, [editedRow]);

  async function handleActionClick(action, file) {
    switch (action) {
      case "Create":
        setShowForm(true);

        break;
      case "Edit":
        setEditedRow(file);
        setShowForm(true);
        break;
      case "Delete":
        deleteAction(file);
        break;
      default:
        break;
    }
  }

  const deleteAction = async (file) => {
    const confirm = window.confirm("Are you sure you want to Delete?");
    if (confirm) {
      const token = await checkAndRefreshToken();
      try {
        await deleteChild(file, token);
        alert("Successfully Deleted Record");

        setFiles((prevFiles) => {
          if (Array.isArray(prevFiles)) {
            return prevFiles.filter((f) => f.ID !== file.ID);
          }
          return [];
        });
        getChildRecommendations();
      } catch (error) {
        console.error("Error deleting file:", error);
        alert("Failed to delete record. Please try again.");
      }
    }
  };

  const editRecommendation = async (file) => {
    try {
      const token = await checkAndRefreshToken();
      const recommendation = file.recommendation;
      delete file.recommendation;
      file.content = recommendation;
      const updatedFile = await editChild(file, token);
      setFiles((prevFiles) =>
        prevFiles.map((f) => (f.id === file.id ? updatedFile : f))
      );
      setFiles(updatedFile);
      getChildRecommendations();
      toast.success("Successfully Updated Record");
    } catch (error) {
      toast.error("Error updating file:", error);
    }
  };

  const actionsList = [
    {
      name: "Create",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="ai ai-Plus"><path d="M12 20v-8m0 0V4m0 8h8m-8 0H4"/></svg>`,
    },
    {
      name: "Edit",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="ai ai-Edit"><path d="M16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621z"/><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3"/></svg>`,
    },
    {
      name: "Delete",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="ai ai-TrashCan"><path d="M4 6h16l-1.58 14.22A2 2 0 0 1 16.432 22H7.568a2 2 0 0 1-1.988-1.78L4 6z"/><path d="M7.345 3.147A2 2 0 0 1 9.154 2h5.692a2 2 0 0 1 1.81 1.147L18 6H6l1.345-2.853z"/><path d="M2 6h20"/><path d="M10 11v5"/><path d="M14 11v5"/></svg>`,
    },
  ];

  return (
    <div className={styles.list__wrapper}>
      {files?.custom_child_recommendations &&
      files.custom_child_recommendations.length > 0 ? (
        <>
          <div className={styles.header__ele}>
            <span>Add Child Recommendation</span>
            <button onClick={() => handleActionClick("Create", {})}>Add</button>
          </div>
          <table ref={tableRef}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>File Type</th>
                <th>Category</th>
                <th>Recommendation</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {files.custom_child_recommendations &&
                files.custom_child_recommendations.map((file, index) => {
                  const isEditingFile =
                    editedRow &&
                    Object.keys(editedRow).length > 0 &&
                    editedRow.id === file.id;
                  return (
                    <tr key={index} data-editing={isEditingFile}>
                      <td>{index + 1}</td>
                      <td>{file.Name || "N/A"}</td>
                      <td data-type="filetype">
                        {Array.isArray(file.file_type) &&
                        file.file_type.length > 0
                          ? file.file_type.map((name) => (
                              <div>{name.Name},</div>
                            ))
                          : file.file_type.Name || "N/A"}
                      </td>
                      <td>{file.Category || "N/A"}</td>
                      <td>{file.Content}</td>
                      <td data-type="actions">
                        <div>
                          {actionsList.map((action) => (
                            <button
                              key={action.name}
                              data-type={action.name}
                              title={action.name}
                              dangerouslySetInnerHTML={{
                                __html: action.icon,
                              }}
                              onClick={() => {
                                handleActionClick(action.name, file);
                              }}
                            ></button>
                          ))}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
      ) : (
        <div className={styles.stateMsg}>
          No data available. Do you want to add Child Recommendation?{" "}
          <span
            href="#"
            onClick={() => {
              setShowForm(true);
            }}
          >
            Click here
          </span>
        </div>
      )}

      {showForm && (
        <FormComponent
          setState={setShowForm}
          BUCKET_NAME={BUCKET_NAME}
          setFileState={setFiles}
          files={files}
          editedRow={editedRow}
          setEditedRow={setEditedRow}
          onSave={addChildRecommendations}
          onUpdate={editRecommendation}
          fileNames={fileNames}
        />
      )}
    </div>
  );
}
